import React from "react";

export default function page() {
  return (
    <div
      style={{
        paddingRight: "20px",
        paddingLeft: "20px",
        paddingBottom: "20px",
      }}
    >
      <h1>Kişisel Verilerin Korunması ve Gizlilik Politikası</h1>
      <p>
        Veri sorumlusu olarak Numil Gıda Ürünleri San. ve Tic. A.Ş.
        (Numil/Nutricia), kişisel bilgilerinizin bu web sitesinde ve/veya bu
        Gizlilik Politikasında açıklanan amaçlarla işlenmesinden sorumludur.
      </p>
      <p>
        Kişiler hakkındaki bilgileri (“Kişisel Veriler”) toplama yöntemlerimizi,
        Kişisel Verileri nasıl sakladığımızı ve kullandığımızı ve gizlilik
        haklarınıza nasıl uyduğumuzu açıklayan bu Gizlilik Politikasını lütfen
        dikkatlice okuyunuz.
      </p>
      <p>
        Numil olarak, bu Gizlilik Politikasını, zaman zaman bu web sitesinde
        yeni bir gizlilik politikası yayınlamak suretiyle değiştirme veya
        güncelleme hakkımız saklıdır.
      </p>
      <b>Hangi Kişisel Verileri hangi amaçlarla işliyoruz?</b>
      <p>
        Hizmetlerimizin çoğu herhangi bir kayıt gerektirmez, bu sayede kim
        olduğunuzu bize söylemeden sitemizi ziyaret edebilirsiniz.
      </p>
      <p>
        Bununla birlikte, bazı hizmetler için; adınız, soyadınız, e-posta
        adresiniz veya telefon numaranız gibi bilgileri içerebilecek Kişisel
        Verilerinizi gönüllü olarak bize vermeniz gerekebilir.
      </p>
      <p>
        Bu kişisel verileri, size ürün, hizmet ve müşteri desteği sağlamak,
        talep ettiğiniz ürün ve hizmetler için size fatura kesmek, ilginizi
        çekebileceğini düşündüğümüz ürün ve hizmetleri pazarlamak veya
        koşullardan açıkça belli olan veya Kişisel Verilerinizi alırken sizi
        bilgilendirdiğimiz diğer amaçlar için sizinle iletişim kurmak için
        toplayabilir ve kullanabiliriz.
      </p>
      <b>Kamuya açık sosyal medyadan toplanan Kişisel Veriler :</b>
      <p>
        Genel sosyal medya platformlarında (bloglar, forumlar vb. dahil) halka
        açık hale getirdiğiniz, Numil/Nutricia ürünleri ile ilgili kişisel
        verileri de toplayabiliriz.
      </p>
      <p>
        “Sosyal medya dinleme” adı verilen bu işlem, örneğin aşağıdakiler gibi
        meşru ticari çıkarlarımıza dayanmaktadır:
      </p>
      <ol type="i">
        <li>
          Sağlık hizmeti çalışanlarının Numil/ Nutricia ürünlerine ilişkin
          deneyim, geri bildirim ve tepkilerini daha iyi anlamak,
        </li>
        <li>
          Numil/ Nutricia ’nın itibarının yanı sıra diğer pazar eğilimlerini
          daha iyi anlamak,
        </li>
        <li>
          Numil/ Nutricia paydaşlarını belirlemek ve onlarla iletişim kurmak
          için.
        </li>
      </ol>
      <b>Web sitesi kullanım analizi için kullanılan Kişisel Veriler :</b>
      <p>
        Bu web sitesine yaptığınız ziyaretinizle ilgili, ziyaret ettiğiniz
        sayfalar, hangi web sitesinden geldiğiniz ve yaptığınız aramalar gibi
        bilgileri toplayabilir ve işleyebiliriz. Bu bilgileri, sitenin içeriğini
        iyileştirmeye yardımcı olmak ve dahili kullanım istatistiklerimiz için
        ve pazar araştırması amaçlarıyla sitemizi kullanan kişiler hakkında
        toplu istatistikler derlemek için kullanabiliriz.
      </p>
      <p>
        Bunu yaparken, kullanıcının alan adını, İnternet servis sağlayıcınızı,
        işletim sisteminizi ve erişim tarih ve saatinizi toplayan “çerezler”
        kurabiliriz. “Çerez”, tarayıcınıza gönderilen ve bilgisayarınızın sabit
        diskinde saklanan küçük bir bilgi parçasıdır. Çerezler bilgisayarınıza
        zarar vermez. Siteye ilk girişte çerezleri kabul edebilir veya
        bazılarını reddedebilirsiniz. Ayrıca çerezlerin tamamını da reddetme
        imkanınız bulunmakta. Ancak, çerezlerimizi kabul etmezseniz, web
        sitemizin tüm işlevlerini kullanamayabilirsiniz. Şu anda web tarayıcı
        “izleme yapma” sinyallerine veya web siteleri veya diğer çevrim içi
        hizmetler üzerinden bilgi toplanmasının devre dışı bırakılması için
        yöntem sağlayan diğer mekanizmalara yanıt vermiyoruz.
      </p>
      <p>
        Üçüncü taraf ortak veya web analitik hizmet ortağı, bu İnternet
        etiketleri/çerezler sayesinde bizim ve diğer sitelerimizin ziyaretçileri
        hakkında veri toplayabilir, bizim için web sitesinin faaliyetleriyle
        ilgili raporlar oluşturabilir ve web sitesinin ve İnternetin
        kullanımıyla ilgili başka hizmetler sağlayabilir.
      </p>
      <p>
        Yasal bir zorunluluk varsa veya bilgileri kendi adlarına işlemek için
        başka tarafları işe alırlarsa, bu tür bilgileri diğer taraflara
        sağlayabilirler. Web etiketleri ve çerezler hakkında daha fazla bilgi
        almak istiyorsanız, lütfen çerez politikamızı okuyunuz.
      </p>
      <p>
        Kişisel Verileri, sizden topladığımız veya sizinle ilgili olarak kamuya
        açık veritabanları, demografik bilgi sağlayıcıları, ortak pazarlama
        ortakları, sosyal medya platformları ve diğer üçüncü taraflar gibi diğer
        kaynaklardan toplayabileceğimiz verilerle birleştirebilir, bir araya
        getirebilir veya anonimleştirebiliriz.
      </p>
      <p>
        Verilerinizi, denetimler, dolandırıcılığın takibi ve önlenmesi, ihlaller
        ve ürün ve hizmetlerimizin diğer olası istismarı dahil olmak üzere iş
        amaçlarımız için ve hizmetlerimizi değiştirmek için kullanabiliriz.
      </p>
      <b>Ayrıca Kişisel Verilerinizi şu durumlarda kullanabiliriz:</b>
      <p>
        Kişisel Verileriniz yalnızca tarafımıza bildirirken belirtilen amaç için
        kullanılacaktır. Bilgileriniz aynı zamanda hizmetlerimiz kapsamında
        yönetim, destek ve geri bildirim almak, güvenlik, yasal veya sözleşmesel
        yükümlülüklerin yerine getirilmesi ve mevzuatta öngörülen diğer
        amaçlarla kullanılacaktır.
      </p>
      <p>Kanun 5. Ve 6. Maddeleri ile uyumlu olacak şekilde ;</p>
      <p>
        Kişisel ve iletişim verileri: Adı- soyadı, telefon, e-mail bilgileri
        iletişim amaçlı kullanılmaktadır.
      </p>
      <p>
        Demografik, Site ve Uygulama Gezinim Verileri ve Diğer veriler:
        kullanıcıların ilgi alanlarını tanımlayarak toplanan verilerin iş
        geliştirme, pazarlama, iletişim gibi operasyonel faaliyetlerin
        yürütülmesi ve geliştirilmesi ile birlikte kullanıcı deneyimini
        arttırarak daha iyi hizmet vermek için kullanılmaktadır.
      </p>
      <b>Bilgilerinizi ne zaman ve kime açıklıyoruz?</b>
      <p>
        Bu Gizlilik Politikasında, Kişisel Verilerinizi paylaşabileceğimiz
        durumlar açıklanmaktadır.
      </p>
      <p>
        6698 Sayılı Kişisel Verilerin Korunması Kanunu’nun 8. Ve 9. Madde
        hükümleri saklı kalmak üzere;
      </p>
      <p>
        Kişisel Verileriniz, işbu internet sitesinin yönetilmesinde
        hizmetlerinden faydalandığımız Üçüncü Taraflara (örneğin internet servis
        sağlayıcılarımız) verilebiliriz. Bu Üçüncü Taraflar Kişisel Verilerinizi
        gizli ve güvenli olarak tutmakla yükümlüdürler.
      </p>
      <p>
        Kişisel Verileriniz, yürürlükteki bir yasa, kamu ve hükümet
        yetkililerinden gelen talepler (mahkeme emri, mahkeme celbi veya hükümet
        düzenlemeleri dahil) nedeniyle bunu yapmamız gerekirse, koşul ve
        şartlarımızı uygulamamız gerekirse, açıklamanın, yasal hakları, bu web
        sitesinin güvenliğini veya bütünlüğünü korumak için gerekli olduğuna iyi
        niyetle inandığımızda, sizin güvenliğinizi veya başkalarının güvenliğini
        korumak için, herhangi bir cezai veya başka türlü bir yasal
        soruşturmanın veya yargılamanın bir parçası olarak veya Kurumsal veya
        ticari bir işlemin geliştirilmesi veya bu hususlarda müzakereye devam
        edilmesi veya bu işlemlerin tamamlanması için makul ölçüde gerekli
        olduğu derecede üçüncü taraflara, danışmanlara ve diğer teşekküllere
        Kişisel Verilerinizi açıklayabiliriz.
      </p>
      <b>Kişisel Verilerinizi nasıl koruyoruz?</b>
      <p>
        Bu web sitesi aracılığıyla toplanan bilgileri korumak için uygun teknik,
        idari ve fiziki koruma önlemlerini kullanmaktayız. Yalnızca yetkili
        Numil çalışanları, Üçüncü Taraf şirketlerin (servis sağlayıcılar)
        çalışanları veya iş ortaklarımızın yetkili çalışanlarının (tüm
        bilgilerin güvenliğini korumayı sözleşme altında kabul etmiş çalışanlar)
        Kişisel Verilerinize erişim hakkı bulunmaktadır.
      </p>
      <p>
        Kişisel Verilerinize erişim sağlayan tüm Üçüncü Taraf ise gizlilik
        anlaşmaları ve Kişisel Verilerin Aktarımı Sözleşmesi imzalamışlardır.
      </p>
      <b>18 yaşın altındaki bireylerden gelen bilgilerle ne yapıyoruz?</b>
      <p>
        Web sitemiz çocuklara yönelik değildir. 18 yaşın altındaki çocukların
        Kişisel Verilerini toplamıyoruz.
      </p>
      <b>Kişisel Verileri ne kadar süreyle saklıyoruz? </b>
      <p>
        Kişisel Verilerinizi, yalnızca toplanma amacını yerine getirmek veya
        yasal veya düzenleyici gerekliliklere uymak için gerekli olan yasal
        süreç boyunca saklayacağız.
      </p>
      <b>Haklarınız nelerdir ve bunları nasıl kullanabilirsiniz? </b>
      <p>
        Kişisel Verileri işlerken, Kişisel Verilerinizin toplanma amaçları
        doğrultusunda doğru ve güncel tutulması için makul adımlar atmaktayız.
      </p>
      <p>
        İlgili kişi olarak Kanun’un 11. maddesi uyarınca aşağıdaki haklara sahip
        olduğunuzu bildiririz:
      </p>
      <ul>
        <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme </li>
        <li>İşlenen kişisel verilerinize ilişkin bilgi talep etme </li>
        <li>
          Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
          kullanılıp kullanılmadığını öğrenme
        </li>
        <li>
          Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı üçüncü
          kişileri bilme,
        </li>
        <li>
          Kişisel verilerinizin eksik veya yanlış işlenmiş olması hâlinde
          bunların düzeltilmesini isteme ve bu kapsamda yapılan işlemin kişisel
          verilerinizin aktarıldığı üçüncü kişilere bildirilmesini isteme
        </li>
        <li>
          Kanun’a ve ilgili diğer kanun hükümlerine uygun olarak işlenmiş
          olmasına rağmen, kişisel veri işlenmesini gerektiren sebeplerin
          ortadan kalkması hâlinde kişisel verilerin silinmesini veya yok
          edilmesini isteme ve bu kapsamda yapılan işlemin kişisel verilerinizin
          aktarıldığı üçüncü kişilere bildirilmesini isteme İşlenen verilerin
          münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle
          aleyhinize bir sonucun ortaya çıkması durumunda buna itiraz etme
        </li>
        <li>
          Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara
          uğramanız hâlinde zararın giderilmesini talep etme
        </li>
      </ul>
      İlgili kişi olarak, Kanun’un 11. maddesinden kaynaklanan haklarınıza
      ilişkin taleplerinizi iletmek ve/veya kişisel verilerinize ilişkin
      haklarınızı kullanmak amacıyla; KVKK.NUMİL@danone.com email adresi
      üzerinden bizimle iletişime geçebilir veya Veri Sorumlusuna Başvuru Usul
      ve Esasları Hakkında Tebliğ’de belirlenen sair yöntemleri kullanarak bu
      taleplerinizi bizlere iletebilirsiniz. Belirtilen yöntemlerle
      taleplerinizi bize iletmeniz durumunda, talebinizin niteliğine göre
      talebiniz en kısa sürede ve en geç otuz gün içinde ücretsiz olarak
      sonuçlandıracaktır. Ancak, işlemin ayrıca bir maliyeti gerektirmesi
      hâlinde, şirket tarafından Kişisel Verileri Koruma Kurulunca belirlenen
      tarifedeki ücret alınabilecektir.
    </div>
  );
}
