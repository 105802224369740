import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";

const rootElement = document.getElementById("root");

const AppWithHelmet = () => (
  <React.StrictMode>
    <Helmet>
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-BH0FBCQ2M8"
      ></script>
      <script>
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'G-BH0FBCQ2M8');
        `}
      </script>
    </Helmet>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<AppWithHelmet />, rootElement);
} else {
  ReactDOM.render(<AppWithHelmet />, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
