import React, { useEffect, useState } from "react";
import bannerImage from "../../assets/bannerImage.png";
import phoneImage from "../../assets/phone_mockup.png";
import logo from "../../assets/nutri-logo.png";
import bannerLogo from "../../assets/bannerLogo.png";
import appStore from "../../assets/appStore.png";
import huaweiLogo from "../../assets/huawei.png";
import playStore from "../../assets/playStore.png";
import nutriciaLogo from "../../assets/nutricia-logo.png";
import appDownload from "../../assets/app_indir.png";
import appDownloadQR from "../../assets/app_indir_qr.png";
import NutriAsistMP4 from "../../assets/videos/nutriasist.mp4";
import NutriAsistWebm from "../../assets/videos/nutriasist.webm";
import "./home.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import metagramLogo from "../../assets/metagram_logo.png";
import ketoplanlayicimLogo from "../../assets/ketoplanlayıcım_logo.png";
import boykilotakipLogo from "../../assets/boykilotakip_logo.png";
import nutrimatikLogo from "../../assets/nutrimatik_logo.png";
import dozajhesapliyiciLogo from "../../assets/dozajhesaplayici_logo.png";
import ketoplanyicimHeaderImage from "../../assets/ketoplanlayicim-header-image.png";
import boykilotakipHeaderImage from "../../assets/boykilotakip-header-image.png";
import nutrimatikHeaderImage from "../../assets/nutrimatik-header-image.png";
import dozajhesaplayiciHeaderImage from "../../assets/dozajhesaplayici-header-image.png";
import metagramHeaderImage from "../../assets/metagram-header-image.png";
import arrowAnimation from "../../assets/arrow-animation.json";
import SliderCard from "../../components/SliderCard/SliderCard";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useCookies } from "react-cookie";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import Lottie from "react-lottie-player";
import { useNavigate } from "react-router-dom";

function Home(props) {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const theme = useTheme();
  const [manageCookies, setManageCookies] = useState(false);
  const [showIcon, setShowIcon] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [usedContent, setUsedContent] = useState(!cookies.cookieConsent);
  const [open, setOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();
  const isPhoneSize = useMediaQuery("(max-width:600px)");
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleAccept = () => {
    setOpen(false);
    setCookie("cookieConsent", true, { path: "/" });
    setUsedContent(false);
  };
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1),
    },
  }));
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  useEffect(() => {
    setIsMobile(isPhoneSize);
    const handleScroll = () => {
      const isBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight;
      setShowIcon(!isBottom);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div className="container">
      {showIcon && (
        <div
          className="scroll-down-icon"
          style={{
            position: "fixed",
            bottom: "20px",
            right: "50%",
            transform: "translateX(50%)",
            cursor: "pointer",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            borderRadius: "50%",
            padding: "5px",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            zIndex: "99",
          }}
        >
          <Lottie
            loop
            animationData={arrowAnimation}
            play
            style={{ width: 50, height: 50 }}
          />
        </div>
      )}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "center",
            color: "#313183",
            fontWeight: 700,
          }}
          sx={{ m: 0, p: 2 }}
          id="customized-dialog-title"
        >
          Kullanım Koşulları
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: "2%",
            top: "2%",
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Typography>
          <Typography gutterBottom>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Typography>
          <Typography gutterBottom>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Typography>
          <Typography gutterBottom>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </Typography>
        </DialogContent>
      </BootstrapDialog>
      {usedContent && isMobile && (
        <div
          className="used-content-confirm"
          style={manageCookies ? { minHeight: 550 } : { minHeight: 200 }}
        >
          <h1 style={{ color: "white" }}>Kullanım Tercihleri</h1>
          <div>
            <p style={{ color: "white" }}>
              Websitemizde, içeriğin tarafınıza sağlanması ve performansın
              optimize edilmesi için gerekli olan çerezler kullanılmaktadır.
            </p>
            <p>
              Sitemizi kullanarak çerezlere (cookie) izin vermektesiniz. Detaylı
              bilgi için{" "}
              <a
                className="jsModalTrigger"
                onClick={() => {
                  navigate("/kullanim-kosullari");
                }}
              >
                Kullanım Koşullarımız'ı
              </a>{" "}
              inceleyebilirsiniz.
            </p>
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid white",
                marginBottom: 10,
              }}
            ></div>
          </div>
          {manageCookies && (
            <div>
              <span className="analytics-header">Analitik Çerezleri</span>
              <p>
                Bu çerezler tarayıcı alışkanlıklarınız ile ilgili bilgi toplar
                ve ziyaretçilerin ilgi alanlarını tespit etmek için kullanılır.
                Bir web sitesini ziyaret ettiğinizi hatırlar ve bu bilgiyi diğer
                kurum ve kuruluşlar ile paylaşabilir. Bu şekilde, size ve ilgi
                alanıza giren bilgilerin size ulaşmasını sağlar.
              </p>
              <div className="check-wrapper">
                <Checkbox {...label} style={{ color: "white" }} />
                <span className="analytics-span">
                  Analitik Çerezlerini Etkinleştir
                </span>
              </div>
              <div
                style={{
                  width: "100%",
                  borderBottom: "1px solid white",
                  marginBottom: 10,
                }}
              ></div>
            </div>
          )}
          {!manageCookies ? (
            <div className="cookie-wrapper">
              <button
                className="cookie-button"
                onClick={() => {
                  setManageCookies(true);
                }}
              >
                Çerezleri Yönet
              </button>
              <button
                className="cookie-button"
                onClick={() => {
                  setCookie("cookieConsent", true, { path: "/" });
                  setUsedContent(false);
                }}
              >
                Hepsini Kabul Et
              </button>
            </div>
          ) : (
            <div className="cookie-wrapper">
              <button
                className="cookie-submit-button"
                onClick={() => {
                  setCookie("cookieConsent", true, { path: "/" });
                  setUsedContent(false);
                }}
              >
                Kaydet
              </button>
            </div>
          )}
        </div>
      )}
      {usedContent && !isMobile && (
        <div className="used-content-confirm-web">
          <p>
            Sitemizi kullanarak çerezlere (cookie) izin vermektesiniz. Detaylı
            bilgi için{" "}
            <a
              className="jsModalTrigger"
              onClick={() => {
                navigate("/kullanim-kosullari");
              }}
            >
              Kullanım Koşullarımız'ı
            </a>{" "}
            inceleyebilirsiniz.
          </p>
          <div className="cookies-web-wrapper">
            <button
              className="cookie-button"
              onClick={() => {
                setManageCookies(true);
                setIsMobile(true);
              }}
            >
              Çerezleri Yönet
            </button>
            <button
              className="cookie-button"
              onClick={() => {
                setCookie("cookieConsent", true, { path: "/" });
                setUsedContent(false);
              }}
            >
              Hepsini Kabul Et
            </button>
          </div>
        </div>
      )}
      <div className="wrapper">
        <div className="bg" style={{ backgroundImage: `url(${bannerImage})` }}>
          <div className="banner-wrapper">
            <div className="banner-info">
              <img
                style={{ visibility: "hidden" }}
                src={bannerLogo}
                alt="Your Image"
                className="banner-logo"
              />
              <img src={bannerLogo} alt="Your Image" className="banner-logo" />
              <div className="download-wrapper">
                <img
                  src={appDownloadQR}
                  alt="Your Image"
                  className="banner-download"
                  style={{ padding: 4, cursor: "auto" }}
                />
                <img
                  src={appDownload}
                  alt="Your Image"
                  className="banner-download"
                  onClick={() =>
                    (window.location.href = "https://krpp.me/nutriasist")
                  }
                />
              </div>
            </div>
            <div className="phone-wrapper">
              <img src={phoneImage} alt="Your Image" className="banner-phone" />
              <img
                src={appDownload}
                alt="Your Image"
                className="banner-phone-download"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="swiper-container">
        <Swiper
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1300: {
              slidesPerView: 3,
            },
            1800: {
              slidesPerView: 4,
            },
          }}
          style={{ width: "100%", height: "100%" }}
          navigation={true}
          autoplay={true}
          modules={[Navigation]}
          className="mySwiper"
        >
          <SwiperSlide className="swiper-slide">
            <SliderCard
              headerImageSrc={metagramHeaderImage}
              logoImageSrc={metagramLogo}
              appType={"metagram"}
              content={
                "Metagram, protein metabolizması bozukluğu olan hastalıklar için özel diyet planları sunar. Hastanızın bilgilerini girdikten sonra, geniş Nutricia ürün kataloğu ile hızlı çözümler elde edebilirsiniz."
              }
            />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <SliderCard
              headerImageSrc={ketoplanyicimHeaderImage}
              logoImageSrc={ketoplanlayicimLogo}
              headerWidth={50}
              appType={"keto"}
              content={
                "Ketoplanlayıcı, ketojenik diyet tarifleri hazırlamada ve en uygun diyeti belirlemede size yardımcı olur. Sizlere geniş diyet yelpazesi ile kolay takip sunar!"
              }
            />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <SliderCard
              headerImageSrc={boykilotakipHeaderImage}
              logoImageSrc={boykilotakipLogo}
              appType={"boykilo"}
              content={
                "Boy Kilo Takip ile çocuk hastalarınız için persentil değeri, z değeri ve VKİ’yi hızlıca bulun. Verilen paylaşım kodları ile gelişimi kolayca takip edin."
              }
            />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <SliderCard
              headerImageSrc={nutrimatikHeaderImage}
              logoImageSrc={nutrimatikLogo}
              appType={"nutrimatik"}
              content={
                "Nutrimatik, malnütrisyonlu hastalar için tıbbi beslenme tedavisi planlamanıza yardımcı olur.\n" +
                "Harris Benedict formülü ve ESPEN Onkoloji kılavuzu önerileriyle hastanızın kalori ve proteinini hesaplar. "
              }
            />
          </SwiperSlide>
          <SwiperSlide className="swiper-slide">
            <SliderCard
              headerImageSrc={dozajhesaplayiciHeaderImage}
              logoImageSrc={dozajhesapliyiciLogo}
              appType={"dozaj"}
              content={
                "Bebek hastalarınızın ihtiyaçlarına göre formül mama miktarı güvenli bir şekilde hesaplanır. Sonrasında bebeklerin sağlıklı beslenmesi için doğru miktarlar sunulur."
              }
            />
          </SwiperSlide>
        </Swiper>
      </div>

      <div className="used-content">
        <span className="how-to-use">Nasıl Kullanabilirim?</span>
        <span className="how-to-use-content">
          Hastalarınızın beslenme ve tedavi süreçlerindeki tüm ihtiyaçlarını tek
          uygulamada toplayan NutriAsist, sizlere ve hastalarınıza kolaylık
          sunar.
        </span>
        <div className="video-player-container">
          <video className="custom-video" width="400" controls>
            <source src={NutriAsistMP4} type="video/mp4" />
            <source src={NutriAsistWebm} type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      <div className="question-content">
        <span className="frequently-asked">Sıkça Sorulan Sorular</span>
        <Accordion
          className="accordion-container"
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className="accordion-tab-header"
          >
            NutriAsist nedir ve ne işe yarar?
          </AccordionSummary>
          <AccordionDetails className="accordion-tab">
            NutriAsist, sağlık uzmanlarının hasta takibi, diyet planlaması ve
            benzeri konularda işlerini kolaylaştırmak için geliştirilmiş bir
            mobil uygulamadır. İçerisinde beş farklı uygulama bulunmaktadır ve
            sağlık profesyonellerine birçok konuda destek sunar.
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion-container"
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel2-header"
            className="accordion-tab-header"
          >
            NutriAsist'i nasıl indirebilirim?
          </AccordionSummary>
          <AccordionDetails className="accordion-tab">
            NutriAsist uygulamasını App Store, App Gallery ve Google Play
            Store'dan arama çubuğuna “NurtiAsist” yazarak kolayca bulabilir ve
            ücretsiz olarak indirebilirsiniz.
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion-container"
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel3-header"
            className="accordion-tab-header"
          >
            NutriAsist'i kimler kullanabilir?
          </AccordionSummary>
          <AccordionDetails className="accordion-tab">
            NutriAsist, sağlık profesyonelleri için tasarlanmıştır ve sadece
            sağlık profesyonelleri bu uygulamayı kullanabilirler.
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion-container"
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4-content"
            id="panel1-header"
            className="accordion-tab-header"
          >
            NutriAsist'teki uygulamalar nelerdir ve nasıl kullanılır?
          </AccordionSummary>
          <AccordionDetails className="accordion-tab">
            NutriAsist içerisinde Metagram, Ketoplanlayıcım, Boy Kilo Takip,
            Nutrimatik ve Dozaj Hesaplayıcı olmak üzere beş farklı uygulama
            bulabilirsiniz. Her bir uygulamanın kendi özellikleri ve kullanım
            talimatları bulunmaktadır. Uygulamaları kullanmak için ilgili başlık
            altındaki talimatları takip edebilirsiniz.
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion-container"
          expanded={expanded === "panel5"}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel5-header"
            className="accordion-tab-header"
          >
            NutriAsist'i kullanmak ücretsiz mi?
          </AccordionSummary>
          <AccordionDetails className="accordion-tab">
            Evet, NutriAsist uygulamasını kullanmak tamamen ücretsizdir.
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion-container"
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel5-header"
            className="accordion-tab-header"
          >
            NutriAsist'te güvenlik nasıl sağlanmaktadır?
          </AccordionSummary>
          <AccordionDetails className="accordion-tab">
            NutriAsist, kullanıcı verilerinin güvenliği için gerekli önlemleri
            almıştır. Verileriniz gizlilik politikamızla korunmaktadır ve
            güvenli bir şekilde saklanmaktadır.
          </AccordionDetails>
        </Accordion>
        <Accordion
          className="accordion-container"
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel5-header"
            className="accordion-tab-header"
          >
            NutriAsist'i nasıl destek alabilirim veya geri bildirimde
            bulunabilirim?
          </AccordionSummary>
          <AccordionDetails className="accordion-tab">
            NutriAsist ile ilgili herhangi bir sorunuz, öneriniz veya geri
            bildiriminiz varsa, lütfen bizimle iletişime geçmekten çekinmeyin.
            Destek ekibimize ulaşmak için "Destek" veya "İletişim" bölümünü
            kullanabilirsiniz. Ayrıca, uygulama içi geri bildirim formunu
            doldurarak da bize ulaşabilirsiniz.
          </AccordionDetails>
        </Accordion>
      </div>
      <div className="nutri-content">
        <div className="nutri-info-wrapper">
          <span className="nutri-info-text">Hemen İndir!</span>
          <span className="nutri-info-content">
            Uygulamayı indirmek için aşağıdaki butonları kullanın.
          </span>
          <div className="nutri-store">
            <img
              src={appStore}
              alt="Your Image"
              className="app-logo"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/tr/app/nutriasist/id6479296366",
                  "_blank"
                )
              }
            />
            <img
              src={playStore}
              alt="Your Image"
              className="app-logo"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.nutriasist",
                  "_blank"
                )
              }
            />
            <img
              src={huaweiLogo}
              alt="Your Image"
              className="app-logo"
              onClick={() =>
                window.open(
                  "https://appgallery.huawei.com/app/C110947341",
                  "_blank"
                )
              }
            />
          </div>
        </div>
        <div className="nutri-wrapper">
          <img src={logo} alt="Your Image" className="app-logo" />
        </div>
      </div>
      <div className="footer-content">
        <img
          src={nutriciaLogo}
          alt="Your Image"
          className="nutricia-logo"
          onClick={() =>
            (window.location.href = "https://www.nutricia.com.tr/")
          }
        />
        <div className="terms-wrapper">
          <span
            className="terms-of-use"
            onClick={() => {
              navigate("/kullanim-kosullari");
            }}
          >
            Kullanım Koşulları
          </span>
        </div>
      </div>
    </div>
  );
}

export default Home;
