import React, {useEffect} from 'react';
import bannerImage from "../assets/bannerImage.png";
import bannerLogo from "../assets/bannerLogo.png";
import appDownloadQR from "../assets/app_indir_qr.png";
import appDownload from "../assets/app_indir.png";
import apple from "../assets/app_store.png"
import googlePlay from "../assets/google_play.png"
import huaweiStore from "../assets/huawei_store.png"
import phoneImage from "../assets/phone_mockup.png";

function RedirectMobile(props) {

    useEffect(()=>{
        window.location.href = 'nutriasist://';
    },[])

    return (
        <div className="wrapper">
            <div className="bg" style={{backgroundImage: `url(${bannerImage})`}}>
                <div className="banner-wrapper" style={{gap:20}}>
                    <div className="banner-info" style={{marginTop:50}}>

                        <img
                            src={bannerLogo}
                            alt="Your Image"
                            className="banner-logo"
                        />
                        <div className="download-wrapper">
                            <img
                                src={appDownloadQR}
                                alt="Your Image"
                                className="banner-download"
                                style={{padding:4,cursor:"auto"}}
                            />
                            <img
                                src={appDownload}
                                alt="Your Image"
                                className="banner-download"
                                onClick={() => window.location.href = "https://krpp.me/nutriasist"}
                            />
                        </div>
                    </div>
                    <div className="phone-wrapper">
                        <img
                            src={phoneImage}
                            alt="Your Image"
                            className="banner-phone"
                        />

                    </div>
                    <span style={{marginBottom:15,color:"white"}}>Uygulamayı indirmek için tıklayınız.</span>
                    <div style={{display:"flex",gap:10,alignItems:"center",justifyContent:"center"}}>
                        <img
                            src={apple}
                            alt="Your Image"
                            className="banner-phone-download"
                            style={{marginTop:0}}
                        />
                        <img
                            src={googlePlay}
                            alt="Your Image"
                            className="banner-phone-download"
                            style={{marginTop:0}}

                        />
                        <img
                            src={huaweiStore}
                            alt="Your Image"
                            className="banner-phone-download"
                            style={{marginTop:0}}

                        />
                    </div>

                </div>

            </div>
        </div>
    );
}

export default RedirectMobile;