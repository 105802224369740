import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL } from "../constants";

function Validate(props) {
  const [validationText, setValidationText] = useState(
    "E-Mail Doğrulanıyor..."
  );
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");
  const validationKey = searchParams.get("validation_key");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      let myHeaders = new Headers();
      myHeaders.append("Accept", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer 919|I6uvVyAvP03wxrM9rdpz4tx9S2PWnHJT5WmmhaTB7155481f"
      );

      let formdata = new FormData();
      formdata.append("email", email);
      formdata.append("validation_key", validationKey);

      let requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      fetch(`${BASE_URL}/api/v1/email-validate`, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result?.status) {
            setValidationText(result?.message);
            setTimeout(() => {
              navigate("/");
            }, 3000);
          } else {
            setTimeout(() => {
              setValidationText(result?.message);
            }, 3000);
          }
        })
        .catch((error) => console.log("error", error));
    };
    fetchData();
  }, []);
  return <div>{validationText}</div>;
}

export default Validate;
