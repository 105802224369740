import React, { useEffect } from "react";
import "./termOfUse.css";
import bannerLogo from "../../assets/bannerLogo.png";
import { FaArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
function TermOfUse(props) {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-container">
      <header className="navigation-bar">
        <div className="footer-logo">
          <img src={bannerLogo} alt="Your Image" className="term-of-use-logo" />
        </div>
        <div className="footer-back" onClick={() => navigate("/")}>
          <FaArrowLeft />
          <span>Geri Dön</span>
        </div>
      </header>
      <main className="content">
        <div className="terms-container">
          <h1
            style={{
              textAlign: "center",
            }}
            className="terms-title"
          >
            NUTRIASIST KULLANIM KOŞULLARI
          </h1>
          <b
            style={{
              textAlign: "left",
            }}
          >
            GİRİŞ
          </b>
          <p>
            Bu Kullanım Koşulları ile Numil Gıda Ürünleri Sanayi ve Ticaret A.Ş.
            (Bundan böyle “Numil” olarak anılacaktır) olarak sahip olduğumuz
            NUTRIASIST mobil uygulamasında (bundan böyle “Uygulama” olarak
            anılacaktır) sunduğumuz hizmetlere ilişkin koşulları ve Uygulama’nın
            kullanılmasına ilişkin kuralları düzenlemekteyiz.{" "}
          </p>
          <p>
            Bu Kullanım Koşulları’nda Uygulama’yı üyelik oluşturan olan hekimler
            “Kullanıcı” olarak anılmaktadır.
          </p>
          <p>
            Uygulama’ya erişen Kullanıcı’dan, Uygulama’yı kullanmadan ve/veya
            Kullanıcı olmadan önce burada yazılı olan Kullanım Şartları’nı
            okuması, belirtilen şartların herhangi birinin uygun olmaması
            halinde Kullanıcı’nın Uygulama’yı kullanmaması beklenmektedir.
            Kullanıcı’nın, Uygulama’dan yararlanmaya başladığı andan itibaren
            işbu Kullanım Şartları’na uymayı taahhüt ettiği kabul edilir.
          </p>
          <p>
            Uygulama sadece hekimlere özel olup Kullanıcı hekim olduğunu, kendi
            tıbbi kanaati, bilgi ve görgüsü çerçevesinde Uygulama’da sunulan
            hizmetlerden faydalanacağını ve kararlarını kendi tıbbi kaanati
            çerçevesinde vereceğini, Uygulama’yı başkaca üçüncü kişilere
            kullandırmayacağını, Uygulama’ya giriş bilgilerinin gizliliğini
            temin edeceğini kabul, beyan ve taahhüt etmektedir.
          </p>
          <b>KULLANIM ŞARTLARI</b>
          <ol>
            <li>
              Kullanıcı için Uygulama’nın kullanımı ücretsizdir. Ancak aşağıda
              yazılı durumlarda Numil, Kullanıcı’nın Uygulama’yı kullanımını
              engelleyebileceği gibi bu eylemleri gerçekleştirilen kişiler
              hakkında yasal yollara başvurma hakkını saklı tutmaktadır. Bu
              haller sınırlı sayıda olmaksızın şu şekilde belirtilmektedir:
              <ul>
                <li>
                  Uygulama’dan faydalanırken Numil’e, Uygulama’ya ve/veya üçüncü
                  kişilere herhangi bir suretle zarar verilmesi,
                </li>
                <li>
                  İşbu Kullanım Koşulları, Kullanıcı Sözleşmesi ve/veya Numil’in
                  bildirdiği her türlü kurallara ve yürürlükteki mevzuata aykırı
                  şekilde başka bir Kullanıcı’nın hesabının izinsiz
                  kullanılması,
                </li>
                <li>
                  Eksik, yanlış, yanıltıcı bilgilerin, genel ahlak kurallarına
                  uygun olmayan ifadeleri içeren ve/veya Türkiye Cumhuriyeti
                  kanunlarına uygun olmayan bilgilerin Uygulama’ya kaydedilmesi,
                </li>
                <li>
                  Fikri mülkiyet hakları Numil’e ait olan, Uygulama’da yer alan
                  her türlü içerik ve unsurların izinsiz, kısmen veya tümüyle
                  kopyalanarak kullanılması veya kullanılmaya çalışılması,
                </li>
                <li>
                  Uygulama’nın genel güvenliğini tehdit edecek, Uygulama’nın ve
                  Uygulama’da kullanılan yazılımların çalışmasını engelleyecek
                  yazılımların, virüslerin ya da bunlara benzer teknolojilerin
                  kullanılması, kullanılmaya çalışılması, bilgilerin alınması,
                  silinmesi, değiştirilmesi ve bunlarla benzer her türlü eylem,
                </li>
                <li>
                  Doğru, güncel olmayan bilgilerin kullanılarak veya başka
                  kişilere ait bilgiler kullanılarak işlem yapılması, Kullanıcı
                  hesabı oluşturması,
                </li>
                <li>
                  Kullanıcı’nın, Uygulama’nın herhangi bir açığından
                  faydalanarak, Numil’e ve/veya Uygulama’ya zarar vermesi,
                  üçüncü tarafların zarara uğraması, haksız bir menfaat
                  sağlaması, tekrarlanan uygulamalar yapması, gerçeğe uymayan
                  fiillerinin Numil tarafından tespit edilmesi.
                </li>
              </ul>
              Yukarıda sayılan eylemlerden bir veya birden fazlasının
              gerçekleşmesi halinde Numil’in kanundan doğan tüm haklarının
              yanında söz konusu işlemleri iptal etme hakkı da saklıdır.
              <li>
                Kullanıcı’nın Uygulama’ya giriş için kullanacağı kullanıcı adı,
                şifre gibi giriş bilgilerinin korunması Kullanıcı’nın kendi
                sorumluluğundadır. Kullanıcı’nın üyelik hesabı kendisine özeldir
                ve üçüncü kişilere devredilemez. Giriş bilgileri üçüncü
                taraflarla paylaşılmamalı ve özenle korunmalıdır. Kullanıcı,
                Uygulama üzerindeki hesabının kendi kusuru nedeniyle başka
                kişiler tarafından kötü niyetle kullanılmasından doğrudan
                sorumludur.
              </li>
              <li>
                Kullanıcı, bir başkasının bilgilerini kullanamayacağı gibi diğer
                Kullanıcıların bilgilerine de izinsiz olarak ulaşamaz veya
                bunları kullanamaz. Bu şekilde bir kullanımdan dolayı
                doğabilecek her türlü idari, hukuki ve cezai yükümlülük
                Kullanıcı’ya aittir.
              </li>
              <li>
                Uygulama’da sunulan hizmetler Numil ve/veya Numil’in anlaşmalı
                olduğu üçüncü taraflarca sonlandırılabilir, geri çekilebilir.
                Böyle bir durumda Kullanıcı’nın her ne ad altında olursa olsun
                bir zarar talebinde bulunma hakkı yoktur.
              </li>
              <li>
                Hesabını silen ve/veya hesabı Numil tarafından haklı sebeple
                silinen Kullanıcı, Uygulama’daki ilgili hizmetlerden
                faydalanamayacaktır.
              </li>
              <li>
                Uygulama, Numil’in kontrolü altında olmayan başka internet
                sitelerine bağlantı veya referans içerebilir. Numil bu bağlantı
                ve referanslardan sorumlu değildir. Uygulama’nın diğer internet
                sitelerine bağlantı verdiği veya yönlendirdiği hallerde tüm
                kullanım ve işlemler için o sitelere ait kullanım şartları,
                gizlilik ve güvenlik politikaları geçerlidir.
              </li>
              <li>
                Kullanıcı, işbu Kullanım Koşulları’na ve Kullanıcı Sözleşmesi’ne
                uyarak Uygulama’daki hizmetlerden faydalanmakla yükümlüdür.
              </li>
              <li>
                Kullanıcı, Uygulama’dan yararlanırken, Türk Ceza Kanunu, Türk
                Borçlar Kanunu, Türk Ticaret Kanunu, Fikir ve Sanat Eserleri
                Kanunu, Sınai Mülkiyet Kanunu ve diğer ilgili mevzuat hükümleri,
                meslek kuralları ile Numil’in yayımlayacağı her türlü duyuru ve
                bildirimlere uymayı kabul ve taahhüt eder. Bu bildirimlere ve
                yasalara aykırı kullanım sebebiyle doğabilecek hukuki, cezai ve
                mali her türlü sorumluluk Kullanıcı’ya aittir.
              </li>
              <li>
                Kullanıcı; Uygulama’da sunulan hizmetlerden alt yapı
                çalışmaları, güncelleme çalışmaları, teknik arızalar ve üçüncü
                tarafların takdirinde olan konular dışında yararlandırılacaktır.
              </li>
              <li>
                Numil verdiği hizmetlerin sürekliliğini sağlamak için, işbu
                Kullanım Şartları’nda herhangi bir bildirimde bulunmaksızın tek
                taraflı değişiklik yapabilir. Numil tek taraflı olarak,
                Uygulama’da verdiği hizmeti tamamen veya kısmen, sürekli veya
                geçici, herhangi bir gerekçe göstermeksizin durdurmak, içeriğini
                değiştirmek veya iptal etmek hakkına sahiptir. Numil, güncel
                Kullanım Koşulları’nı yine aynı link altında Uygulama’da
                yayınlayacaktır. Güncel Kullanım Şartları, yayınlandığı andan
                itibaren geçerli olacak ve Uygulama’nın kullanımı o andan
                itibaren güncel Kullanım Şartları’na bağlı olacaktır.
              </li>
              <li>
                Uygulama’da uygun ve gerekli saklama ve güvenlik önlemleri
                alınmasına karşın internet ortamında toplanan verilere yönelik
                izinsiz erişim riski her zaman mevcuttur. Bu nedenle, üçüncü
                şahısların yetkisiz erişiminden tam olarak korunacağı hiçbir
                zaman Numil tarafından tam olarak garanti edilmemektedir.
              </li>
            </li>
          </ol>
          <b>FİKRİ MÜLKİYET HAKLARI</b>
          <p>
            Uygulama’da sunulan ve bunlarla sınırlı olmayan her türlü unsurun,
            içeriğin, görsellerin, yazıların, grafiklerin, animasyonların,
            markaların, yazılım kodlarının ve her türlü eser yürürlükte bulunan
            fikri ve sınai haklar mevzuatı kapsamında korunmakta olup her türlü
            hakkı saklıdır. Aksi belirtilmedikçe ticari ya da kişisel amaçlarla
            izinsiz ve kaynak göstermeden kullanılamaz. Uygulama’da yer alan
            herhangi bir unsuru, içeriği herhangi bir mecrada yayınlamak veya
            Uygulama’ya Numil’in yazılı izni olmadan link vermek yasaktır.
          </p>
          <b>SON HÜKÜMLER</b>
          <ol>
            <li>
              Kullanıcı’nın, Kullanım Koşulları’na aykırı davranmaları sonucunda
              oluşacak durumlarda, diğer Kullanıcıların, Numil’in ve/veya üçüncü
              kişilerin uğrayacağı her türlü zararı tazmin etmekle yükümlüdür.
            </li>
            <li>
              Bu Kullanım Koşulları’nın uygulanması Türkiye Cumhuriyeti
              kanunlarına tabidir ve çıkacak uyuşmazlıklarda İstanbul Anadolu
              Mahkemeleri ve İcra Daireleri yetkilidir.
            </li>
            <li>
              Bu Kullanım Koşulları, Uygulama’nın kullanılmaya devam edildiği ve
              Numil tarafından güncellenmediği sürece yürürlükte kalacaktır.
            </li>
          </ol>
        </div>
      </main>
    </div>
  );
}

export default TermOfUse;
