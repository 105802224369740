import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from "./pages/Home/home";
import Validate from "./pages/validate";
import Redirect from "./pages/redirect";
import Message from "./pages/message";
import TermOfUse from "./pages/TermOfUse/termOfUse";
import RedirectMobile from "./pages/redirectMobile";

import KVKK from "./pages/kvkk/kvkk";
import Reference from "./pages/Reference/reference";

function App() {
  return (
      <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/validate" element={<Validate />}/>
          <Route path="/download" element={<Redirect />}/>
          <Route path="/indir" element={<Redirect />}/>
          <Route path="/message" element={<Message />}/>
          <Route path="/kullanim-kosullari" element={<TermOfUse />}/>
          <Route path="/app" element={<RedirectMobile />}/>
          <Route path="/kvkk" element={<KVKK />} />
          <Route path="/reference" element={<Reference />} />
      </Routes>
  );
}

export default App;
