import React from "react";

export default function Reference() {
  return (
    <div>
      <ul>
        <li>
          <a target="_blank" href="/media/loprofin.pdf">
            Loprofin drink LQ
          </a>
        </li>
        <li>
          <a target="_blank" href="/media/neocate-junior.pdf">
            Neocate Junior
          </a>
        </li>
        <li>
          <a target="_blank" href="/media/lpdrink.pdf">
            LP-Drink
          </a>
        </li>
        <li>
          <a target="_blank" href="/media/diasipcilek.pdf">
            Diasip Çilek
          </a>
        </li>
        <li>
          <a target="_blank" href="/media/diasipvanilla.pdf">
            Diasip Vanilya
          </a>
        </li>
        <li>
          <a target="_blank" href="/media/peptisorb.pdf">
            Nutrini Peptisorb
          </a>
        </li>
        <li>
          <a target="_blank" href="/media/nutrinienergy.pdf">
            Nutrini Energy
          </a>
        </li>
        <li>
          <a target="_blank" href="/media/neocate.pdf">
            Neocate
          </a>
        </li>
        <li>
          <a target="_blank" href="/media/tyr2secunda.pdf">
            TYR 2 Secunda
          </a>
        </li>
        <li>
          <a target="_blank" href="/media/lophlexLq.pdf">
            Lophlex LQ
          </a>
        </li>
      </ul>
    </div>
  );
}
