import React from 'react';
import "./slider-card.css"

function SliderCard({headerImageSrc,logoImageSrc,content,appType}) {
    return (
        <div className="card-container">
            <div className="card-wrapper">
                <div className="image-container">
                    <img
                        src={headerImageSrc}
                        alt="Your Image"
                        className="header-image"
                    />
                </div>
                <div className="header-container">
                    <img
                        src={logoImageSrc}
                        alt="Your Image"
                        className={`header-logo ${appType}`}
                    />
                </div>
                <div className="content-container">
                    <span>{content}</span>
                </div>
            </div>

        </div>
    );
}

export default SliderCard;